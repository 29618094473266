import styled from "styled-components";
import { useRef } from "react";
import { Project } from "../../common/Project";

import virusimg from "../../../assets/WorkViews/virustrackr.JPG";
import virusgif from "../../../assets/WorkViews/virustrackr.gif";
import typeimg from "../../../assets/WorkViews/typeking.JPG";
import typegif from "../../../assets/WorkViews/typeking.gif";
import todoimg from "../../../assets/WorkViews/todolist.JPG";
import ace from "../../../assets/WorkViews/ace.png";
import sparkle from "../../../assets/WorkViews/sparkle.png";
import todogif from "../../../assets/WorkViews/todolist.gif";
import ezgif from "../../../assets/WorkViews/ezgif.gif";
import ezimg from "../../../assets/WorkViews/ezimg.JPG";
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 600px) {
    padding-bottom: 5em;
  }
`;
const Stacker = styled.div.attrs({
  className: "stack-cards js-stack-cards",
})`
  .stack-cards__item {
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 4em;
  }
`;
const Container = styled.div`
  padding: 1em;
  padding-bottom: 0px;
  @media (min-width: 700px) {
    padding: 2em;
    max-width: 700px;
    font-size: 1.1em;
    padding-bottom: 0px;
  }
  @media (min-width: 1000px) {
    max-width: 1000px;
    font-size: 1.2em;
    padding-bottom: 0px;
  }
`;

export const Creations = () => {
  // Utility function
  function Util() {}

  /* 
	class manipulation functions
*/
  Util.hasClass = function (el, className) {
    if (el.classList) return el.classList.contains(className);
    else
      return !!el.className.match(
        new RegExp("(\\s|^)" + className + "(\\s|$)")
      );
  };

  Util.addClass = function (el, className) {
    var classList = className.split(" ");
    if (el.classList) el.classList.add(classList[0]);
    else if (!Util.hasClass(el, classList[0]))
      el.className += " " + classList[0];
    if (classList.length > 1) Util.addClass(el, classList.slice(1).join(" "));
  };

  Util.removeClass = function (el, className) {
    var classList = className.split(" ");
    if (el.classList) el.classList.remove(classList[0]);
    else if (Util.hasClass(el, classList[0])) {
      var reg = new RegExp("(\\s|^)" + classList[0] + "(\\s|$)");
      el.className = el.className.replace(reg, " ");
    }
    if (classList.length > 1)
      Util.removeClass(el, classList.slice(1).join(" "));
  };

  Util.toggleClass = function (el, className, bool) {
    if (bool) Util.addClass(el, className);
    else Util.removeClass(el, className);
  };

  Util.setAttributes = function (el, attrs) {
    for (var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  };

  /* 
  DOM manipulation
*/
  Util.getChildrenByClassName = function (el, className) {
    var children = el.children,
      childrenByClass = [];
    for (var i = 0; i < el.children.length; i++) {
      if (Util.hasClass(el.children[i], className))
        childrenByClass.push(el.children[i]);
    }
    console.log(children);
    return childrenByClass;
  };

  Util.is = function (elem, selector) {
    if (selector.nodeType) {
      return elem === selector;
    }

    var qa =
        typeof selector === "string"
          ? document.querySelectorAll(selector)
          : selector,
      length = qa.length,
      returnArr = [];
    console.log(returnArr);
    while (length--) {
      if (qa[length] === elem) {
        return true;
      }
    }

    return false;
  };

  /* 
	Animate height of an element
*/
  Util.setHeight = function (start, to, element, duration, cb) {
    var change = to - start,
      currentTime = null;

    var animateHeight = function (timestamp) {
      if (!currentTime) currentTime = timestamp;
      var progress = timestamp - currentTime;
      var val = parseInt((progress / duration) * change + start);
      element.style.height = val + "px";
      if (progress < duration) {
        window.requestAnimationFrame(animateHeight);
      } else {
        cb();
      }
    };

    //set the height of the element before starting animation -> fix bug on Safari
    element.style.height = start + "px";
    window.requestAnimationFrame(animateHeight);
  };

  /* 
	Smooth Scroll
*/

  Util.scrollTo = function (final, duration, cb, scrollEl) {
    var element = scrollEl || window;
    var start = element.scrollTop || document.documentElement.scrollTop,
      currentTime = null;

    if (!scrollEl) start = window.scrollY || document.documentElement.scrollTop;

    var animateScroll = function (timestamp) {
      if (!currentTime) currentTime = timestamp;
      var progress = timestamp - currentTime;
      if (progress > duration) progress = duration;
      var val = Math.easeInOutQuad(progress, start, final - start, duration);
      element.scrollTo(0, val);
      if (progress < duration) {
        window.requestAnimationFrame(animateScroll);
      } else {
        cb && cb();
      }
    };

    window.requestAnimationFrame(animateScroll);
  };

  /* 
  Focus utility classes
*/

  //Move focus to an element
  Util.moveFocus = function (element) {
    if (!element) element = document.getElementsByTagName("body")[0];
    element.focus();
    if (document.activeElement !== element) {
      element.setAttribute("tabindex", "-1");
      element.focus();
    }
  };

  /* 
  Misc
*/

  Util.getIndexInArray = function (array, el) {
    return Array.prototype.indexOf.call(array, el);
  };

  Util.cssSupports = function (property, value) {
    if ("CSS" in window) {
      return CSS.supports(property, value);
    } else {
      var jsProperty = property.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });
      return jsProperty in document.body.style;
    }
  };

  // merge a set of user options into plugin defaults
  // https://gomakethings.com/vanilla-javascript-version-of-jquery-extend/

  // Check if Reduced Motion is enabled
  Util.osHasReducedMotion = function () {
    if (!window.matchMedia) return false;
    var matchMediaObj = window.matchMedia("(prefers-reduced-motion: reduce)");
    if (matchMediaObj) return matchMediaObj.matches;
    return false; // return false if not supported
  };

  /* 
	Polyfills
*/
  //Closest() method
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
      var el = this;
      if (!document.documentElement.contains(el)) return null;
      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }

  //Custom Event() constructor
  if (typeof window.CustomEvent !== "function") {
    function CustomEvent(event, params) {
      params = params || {
        bubbles: false,
        cancelable: false,
        detail: undefined,
      };
      var evt = document.createEvent("CustomEvent");
      evt.initCustomEvent(
        event,
        params.bubbles,
        params.cancelable,
        params.detail
      );
      return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
  }

  /* 
	Animation curves
*/
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  /* JS Utility Classes */
  (function () {
    // make focus ring visible only for keyboard navigation (i.e., tab key)
    var focusTab = document.getElementsByClassName("js-tab-focus");
    function detectClick() {
      if (focusTab.length > 0) {
        resetFocusTabs(false);
        window.addEventListener("keydown", detectTab);
      }
      window.removeEventListener("mousedown", detectClick);
    }

    function detectTab(event) {
      if (event.keyCode !== 9) return;
      resetFocusTabs(true);
      window.removeEventListener("keydown", detectTab);
      window.addEventListener("mousedown", detectClick);
    }

    function resetFocusTabs(bool) {
      var outlineStyle = bool ? "" : "none";
      for (var i = 0; i < focusTab.length; i++) {
        focusTab[i].style.setProperty("outline", outlineStyle);
      }
    }
    window.addEventListener("mousedown", detectClick);
  })();
  // Tutorial - https://codyhouse.co/tutorials/how-stacking-cards
  (function () {
    var StackCards = function (element) {
      this.element = element;
      this.items = this.element.getElementsByClassName("js-stack-cards__item");
      this.scrollingFn = false;
      this.scrolling = false;
      initStackCardsEffect(this);
      initStackCardsResize(this);
    };

    function initStackCardsEffect(element) {
      // use Intersection Observer to trigger animation
      setStackCards(element); // store cards CSS properties
      var observer = new IntersectionObserver(
        stackCardsCallback.bind(element),
        { threshold: [0, 1] }
      );
      observer.observe(element.element);
    }

    function initStackCardsResize(element) {
      // detect resize to reset gallery
      element.element.addEventListener("resize-stack-cards", function () {
        setStackCards(element);
        animateStackCards.bind(element);
      });
    }

    function stackCardsCallback(entries) {
      // Intersection Observer callback
      if (entries[0].isIntersecting) {
        if (this.scrollingFn) return; // listener for scroll event already added
        stackCardsInitEvent(this);
      } else {
        if (!this.scrollingFn) return; // listener for scroll event already removed
        window.removeEventListener("scroll", this.scrollingFn);
        this.scrollingFn = false;
      }
    }

    function stackCardsInitEvent(element) {
      element.scrollingFn = stackCardsScrolling.bind(element);
      window.addEventListener("scroll", element.scrollingFn);
    }

    function stackCardsScrolling() {
      if (this.scrolling) return;
      this.scrolling = true;
      window.requestAnimationFrame(animateStackCards.bind(this));
    }

    function setStackCards(element) {
      // store wrapper properties
      element.marginY = "1.5em";
      getIntegerFromProperty(element); // convert element.marginY to integer (px value)
      element.elementHeight = element.element.offsetHeight;

      // store card properties
      var cardStyle = getComputedStyle(element.items[0]);
      element.cardTop = Math.floor(
        parseFloat(cardStyle.getPropertyValue("top"))
      );
      element.cardHeight = Math.floor(
        parseFloat(cardStyle.getPropertyValue("height"))
      );

      // store window property
      element.windowHeight = window.innerHeight;

      // reset margin + translate values
      if (isNaN(element.marginY)) {
        element.element.style.paddingBottom = "0px";
      } else {
        element.element.style.paddingBottom =
          element.marginY * (element.items.length - 1) + "px";
      }

      for (var i = 0; i < element.items.length; i++) {
        if (isNaN(element.marginY)) {
          element.items[i].style.transform = "none;";
        } else {
          element.items[i].style.transform =
            "translateY(" + element.marginY * i + "px)";
        }
      }
    }

    function getIntegerFromProperty(element) {
      var node = document.createElement("div");
      node.setAttribute(
        "style",
        "opacity:0; visbility: hidden;position: absolute; height:" +
          element.marginY
      );
      element.element.appendChild(node);
      element.marginY = parseInt(
        getComputedStyle(node).getPropertyValue("height")
      );
      element.element.removeChild(node);
    }

    function animateStackCards() {
      if (isNaN(this.marginY)) {
        // --stack-cards-gap not defined - do not trigger the effect
        this.scrolling = false;
        return;
      }

      var top = this.element.getBoundingClientRect().top;

      if (
        this.cardTop -
          top +
          this.element.windowHeight -
          this.elementHeight -
          this.cardHeight +
          this.marginY +
          this.marginY * this.items.length >
        0
      ) {
        this.scrolling = false;
        return;
      }

      for (var i = 0; i < this.items.length; i++) {
        // use only scale
        var scrolling =
          this.cardTop - top - i * (this.cardHeight + this.marginY);
        if (scrolling > 0) {
          var scaling =
            i === this.items.length - 1
              ? 1
              : (this.cardHeight - scrolling * 0.05) / this.cardHeight;
          this.items[i].style.transform =
            "translateY(" + this.marginY * i + "px) scale(" + scaling + ")";
        } else {
          this.items[i].style.transform =
            "translateY(" + this.marginY * i + "px)";
        }
      }

      this.scrolling = false;
    }

    // initialize StackCards object
    var stackCards = document.getElementsByClassName("js-stack-cards"),
      intersectionObserverSupported =
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype,
      reducedMotion = Util.osHasReducedMotion();

    if (
      stackCards.length > 0 &&
      intersectionObserverSupported &&
      !reducedMotion
    ) {
      var stackCardsArray = [];
      for (var i = 0; i < stackCards.length; i++) {
        (function (i) {
          stackCardsArray.push(new StackCards(stackCards[i]));
        })(i);
      }

      var resizingId = false,
        customEvent = new CustomEvent("resize-stack-cards");

      window.addEventListener("resize", function () {
        clearTimeout(resizingId);
        resizingId = setTimeout(doneResizing, 500);
      });

      function doneResizing() {
        for (var i = 0; i < stackCardsArray.length; i++) {
          (function (i) {
            stackCardsArray[i].element.dispatchEvent(customEvent);
          })(i);
        }
      }
    }
  })();

  let myRef = useRef();
  return (
    <div>
      <Wrapper>
        <Container>
          <Stacker>
            <Project
              first="true"
              gif={virusgif}
              image={sparkle}
              title="Sparkle Hair Extension Bar"
              text="In production."
              tech="React"
              tech2=""
              linkclick={() => window.open("https://sparklehair.vercel.app")}
              gitclick={() => window.open("https://sparklehair.vercel.app")}
            />

            <Project
              gif={typegif}
              image={ace}
              title="Ace Golf Bar & Lounge"
              text="The Ace Golf Bar."
              tech="React"
              tech2=""
              linkclick={() => window.open("https://acegolfbar.ca")}
              gitclick={() => window.open("https://acegolfbar.ca")}
            />
            <Project
              gif={virusgif}
              image={virusimg}
              title="Virus-Trackr"
              text="Realtime COVID19 data fetched and displayed on simple views. Data pulled from covid19api.com."
              tech="React"
              tech2="Styled-Components"
              linkclick={() =>
                window.open("https://slippax.github.io/virus-trackr")
              }
              gitclick={() =>
                window.open("https://github.com/slippax/virus-trackr")
              }
            />

            <Project
              gif={typegif}
              image={typeimg}
              title="Type King"
              text="A typing speed test with leaderboard support and profile saving. Try to beat the top score!"
              tech="React"
              tech2="Google Firebase"
              linkclick={() =>
                window.open("https://slippax.github.io/type-god")
              }
              gitclick={() =>
                window.open("https://github.com/slippax/type-god")
              }
            />

            <Project
              gif={todogif}
              image={todoimg}
              title="Todoz"
              text="A modern take of to do posted notes handled with Redux. Ready to tackle your next days agenda!"
              tech="React"
              tech2="Redux"
              linkclick={() => window.open("https://slippax.github.io/todoz")}
              gitclick={() => window.open("https://github.com/slippax/todoz")}
            />

            <Project
              gif={ezgif}
              image={ezimg}
              title="Ez-Sens"
              text="An FPS sensitivity converter for popular PC games. Convert your sens with this simple tool."
              tech="React"
              tech2="CSS Modules"
              linkclick={() =>
                window.open("https://slippax.github.io/Ez-Sens/")
              }
              gitclick={() => window.open("https://github.com/slippax/Ez-Sens")}
            />
          </Stacker>
          {/* <Fade right>
          <ProjectAlt title="Weather Grabbr" text="A weather app featuring humidity, sky conditions and much more. Search for weather data anywhere worldwide." tech="React" tech2="OpenWeather" linkclick={() => window.open("https://slippax.github.io/weather-grabbr")} gitclick={() => window.open("https://github.com/slippax/weather-grabbr")} />
          </Fade>
          <Fade left>
          <Project title="Portfolio V1" text="My first take on a portfolio site. An interactive experience with smooth scrolling and flashy animations" tech="React" tech2="TypeScript" linkclick={() => window.open("https://slippax.github.io/my-portfolio")} gitclick={() => window.open("https://github.com/slippax/my-portfolio")} />
          </Fade>
           */}
        </Container>
      </Wrapper>
      <div ref={myRef}></div>
    </div>
  );
};
